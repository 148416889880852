import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import * as VK from '@vkontakte/vkui';
import {authUser} from './js/services/auth';
import {connect} from 'react-redux';
import '@vkontakte/vkui/dist/vkui.css';
import {popHistory} from './js/store/globals/actions';
import {authUserFinish, authUserStart} from './js/store/vk/actions';
import {goBack, push} from 'react-router-redux';
import RoutesAndPanels from './_RoutesAndPanels';
import './App.css';
import {getFriendsAvatar, initApp} from './js/services/vk';
import {getVisits} from './js/services/axios';
import {store} from './index';
import {
    finishEventsLoading,
    setFutureEvents,
    setPastEvents,
    startEventsLoading,
} from './js/store/events/actions';

const App = ({pageId, popout = {}, modal = null, history, dispatch}) => {
    const eventsWithAvatars = async (token, events) => {
        const visitorsId = events
            .filter((visit) => {
                const {visitors} = visit;
                return visitors.length === 1;
            })
            .map((visit) => {
                return visit.visitors[0].vk_id;
            });

        const uniqueVisitorsId = [...new Set(visitorsId)];
        if (uniqueVisitorsId.length > 0) {
            const friendsAvatarData = await getFriendsAvatar(token, uniqueVisitorsId);
            const newEventsList = events.map((event) => {
                const {visitors} = event;
                if (visitors.length === 1) {
                    let newEvent = event;

                    friendsAvatarData.forEach((avatar) => {
                        if (+visitors[0].vk_id === avatar.id) {
                            newEvent = {
                                ...event,
                                visitors: [{
                                    ...visitors[0],
                                    avatar: avatar.photo_50,
                                }],
                            };
                        }
                    });

                    return newEvent;
                }

                return event;
            });

            return newEventsList;
        } else {
            return events;
        }
    };

    // TODO refactor
    // не уверена что это хороший подход, но других идей пока нет
    const getEvents = async () => {
        await dispatch(startEventsLoading());
        const token = store.getState().vk.accessToken;

        const getFutureEvents = await dispatch(getVisits('future'));
        const getPastEvents = await dispatch(getVisits('past'));
        if (getPastEvents) {
            const newPastEvents = await eventsWithAvatars(token, getPastEvents.data);

            await dispatch(setPastEvents(newPastEvents));
        } else {
            await dispatch(setPastEvents([]));
        }
        if (getFutureEvents) {
            const newFutureEvents = await eventsWithAvatars(token, getFutureEvents.data);

            await dispatch(setFutureEvents(newFutureEvents));
        } else {
            await dispatch(setFutureEvents([]));
        }

        await dispatch(finishEventsLoading());
    };

    const fetchData = async () => {
        await dispatch(authUserStart());
        const authResult = await dispatch(authUser());
        await dispatch(authUserFinish());

        if (authResult.result === 'fail') {
            if (authResult.error === 'jwt' || authResult.error === 'token') {
                dispatch(push('/wrong-user'));
            }
            if (authResult.error === 'unknown') {
                dispatch(push('/wrong-user'));
            }
        } else {
            await getEvents();
            dispatch(initApp());
        }
    };

    // useEffect не должен возвращать Promise, поэтому так
    useEffect(() => {
        fetchData();
    }, []);

    const swipeBack = async () => {
        await dispatch(goBack());
        await dispatch(popHistory());
    };

    const hasNotHeader = !pageId || pageId === 'main';

    return (
        <VK.ConfigProvider isWebView={true}>
            <VK.Root activeView="mainView">
                <VK.View
                    id="mainView"
                    activePanel={RoutesAndPanels.sharedInstance.getSelectedPanel(pageId)}
                    history={history}
                    popout={popout}
                    modal={modal}
                    onSwipeBack={() => swipeBack()}
                    header={!hasNotHeader}
                >
                    {RoutesAndPanels.sharedInstance.renderPanels()}
                </VK.View>
            </VK.Root>
        </VK.ConfigProvider>
    );
};

function mapStateToProps(state) {
    return {
        history: state.globals.history,
        popout: state.globals.popout,
        modal: state.globals.modal,
        accessToken: state.vk.accessToken,
    };
}

App.propTypes = {
    history: PropTypes.array.isRequired,
    popout: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    modal: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    dispatch: PropTypes.func.isRequired,
    pageId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(App);
