import connect from '@vkontakte/vk-connect';
import * as VKActions from '../store/vk/actions';
import {store} from '../../index';

const API_VERSION = '5.100';

export const initApp = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    let handler = async (e) => {
      let vkEvent = e.detail;
      let type = vkEvent.type;
      let data = vkEvent.data;

      if (type === 'VKWebAppUpdateConfig') {
        document.body.setAttribute('scheme', data.scheme);
        dispatch(VKActions.setInsets(data.insets));
      }
    };
    connect.subscribe(handler);
    connect.send('VKWebAppInit', {'no_toolbar': true});
  });
};

export const getAuthToken = (scope = '') => (dispatch) => {
  const appId = +store.getState().query.vk_app_id;

  return connect
    .sendPromise('VKWebAppGetAuthToken', {'app_id': appId, 'scope': scope})
    .then((data) => {
      dispatch(VKActions.setAuthTokenSuccess(data.access_token));
      return data.access_token;
    })
    .catch((error) => {
      dispatch(VKActions.setAuthTokenFail());
    });
};

export const getUserInfo = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    let handler = async (e) => {
      e = e.detail;
      let found = false;

      switch (e.type) {
        case 'VKWebAppGetUserInfoResult':
          found = true;
          resolve(dispatch(VKActions.setVkUser(e.data)));
          return e.data;

        case 'VKWebAppGetUserInfoFailed':
          found = true;
          reject(dispatch(VKActions.setVkUserFail()));
          return '';

        default:
          return;
      }

      if (found) {
        connect.unsubscribe(handler);
      }
    };
    connect.subscribe(handler);
    connect.send('VKWebAppGetUserInfo', {});
  });
};

export const getFriendFromList = () => {
  return new Promise((resolve, reject) => {
    let handler = async (e) => {
      e = e.detail;
      let found = false;

      switch (e.type) {
        case 'VKWebAppGetFriendsResult':
          found = true;
          resolve({result: 'success', data: e.data.users});
          break;

        case 'VKWebAppGetFriendsFailed':
          found = true;
          resolve({result: 'fail'});
          break;

        default:
          return;
      }
      if (found) connect.unsubscribe(handler);
    };
    connect.subscribe(handler);
    connect.send('VKWebAppGetFriends', {});
  });
};

export const getUser = (USER_IDS, accessToken) => {
  return apiRequest(
    'users.get',
    {user_ids: USER_IDS},
    accessToken,
    (data) => {
      return {result: 'success', data};
    },
    () => {
      return {result: 'fail'};
    },
  );
};

export const getAllMyFriends = (accessToken, offset) => {
  return apiRequest(
    'friends.get',
    {count: 5000, offset, order: 'name', fields: 'photo_50'},
    accessToken,
    (data) => {
      return {result: 'success', data};
    },
    () => {
      return {result: 'fail'};
    },
  );
};

export const getFriendsAvatar = (accessToken, ids) => {
  if (typeof ids === 'object') {
    ids = ids.join(',');
  }
  return apiRequest(
    'users.get',
    {user_ids: ids, fields: 'photo_50'},
    accessToken,
    (data) => {
      return {result: 'success', data};
    },
    () => {
      return {result: 'fail'};
    },
  );
};

export const apiRequest = async (
  method,
  params = {},
  accessToken = '',
  successCallback = undefined,
  failCallback = undefined
) => {
  const requestId = await getNewRequestId();
  params['access_token'] = accessToken;

  if (params['v'] === undefined) {
    params['v'] = API_VERSION;
  }

  return connect
    .sendPromise('VKWebAppCallAPIMethod', {
      'method': method,
      'params': params,
      'request_id': requestId,
    })
    .then((data) => {
      if (successCallback !== undefined) {
        successCallback(data.response);
      }
      return data.response;
    })
    .catch((error) => {
      if (failCallback !== undefined) {
        failCallback(error);
      }
      return error;
    });
};

function getNewRequestId() {
  return (Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)).toString();
}
