import React, {PureComponent} from 'react';
import {Cell} from '@vkontakte/vkui';
import Icon28HelpOutline from '@vkontakte/icons/dist/28/help_outline';
import './Help.css';

class Help extends PureComponent {
  render() {
    return (
      <Cell
        expandable
        before={<Icon28HelpOutline fill="#aa65f0"/>}
        className="Help"
      >
        <a
          className="Help__link"
          href="https://vk.com/vkdevcrew"
          target="_blank"
          rel="noopener noreferrer"
        />
        Написать в поддержку
      </Cell>
    );
  };
}

export default Help;

