import * as types from './actionTypes';

export const setFriends = (friends) => (
  {
    type: types.SET_FRIENDS,
    friends,
  }
);

export const setFriendsLoading = () => (
  {
    type: types.SET_FRIENDS_LOADING,
  }
);

export const setFriendsLoaded = () => (
  {
    type: types.SET_FRIENDS_LOADED,
  }
);
