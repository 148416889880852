import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Cell, CellButton, Button} from '@vkontakte/vkui';
import LoaderSpinner from '../LoaderSpinner';
import {prettifyDate} from '../../services/helpers';
import Icon28User from '@vkontakte/icons/dist/28/user';
import './EventsList.css';

class EventsList extends PureComponent {
    renderAvatar(avatar) {
        if (!avatar) {
            return (
                <Avatar
                    size={40}
                >
                    <Icon28User/>
                </Avatar>
            );
        }

        return (
            <Avatar
                size={40}
                src={avatar}
            />
        );
    }

    renderAvatarNum(num) {
        if (num <= 99) {
            return (
                <div className="EventsList__guestsAvatar">
                    {num}
                </div>
            );
        } else {
            return (
                <div className="EventsList__guestsAvatar EventsList__guestsAvatar--maxNum">
                    99+
                </div>
            );
        }
    }

    renderCard() {
        const {
            list,
            handleOpenDetails,
        } = this.props;

        return list.map((event) => {
            const visitors = event.visitors;
            const guestCounter = visitors.length;
            const isOneGuest = guestCounter === 1;

            return (
                <Cell
                    className="EventList__cell"
                    key={event.id}
                    before={isOneGuest
                        ? this.renderAvatar(visitors[0].avatar)
                        : (
                            <Avatar
                                size={40}
                            >
                                {this.renderAvatarNum(guestCounter)}
                            </Avatar>
                        )
                    }
                    expandable
                    onClick={() => handleOpenDetails(event.id)}
                    indicator={prettifyDate(event.visit_date, '-')}
                >
                    {isOneGuest
                        ? visitors[0].name
                        : event.title ? event.title : 'Несколько гостей'
                    }
                </Cell>
            );
        });
    }

    render() {
        const {
            list,
            handleShowAll,
            isEventsLoading,
            isMainPage,
            isPastEventsExist,
        } = this.props;
        const isShowContent = list.length !== 0;

        return (
            <React.Fragment>
                {isEventsLoading && <LoaderSpinner/>}
                {!isEventsLoading && isShowContent && (
                    <div className="EventList__content">
                        {this.renderCard()}
                        {isMainPage && (
                            <CellButton
                                before=""
                                onClick={handleShowAll}
                                className="EventList__button"
                            >
                                Показать все посещения
                            </CellButton>
                        )}
                    </div>
                )
                }
                {!isEventsLoading && !isShowContent && isMainPage &&
                    <div className="EventList__emptyListHelpers">
                        {isPastEventsExist
                          ? (
                            <Button
                              level="tertiary"
                              size="xl"
                              onClick={handleShowAll}
                              className="EventList__pastButton"
                            >
                                Показать прошедшие посещения
                            </Button>
                          )
                          : (
                            <div className="EventList__emptyList">
                                Вы пока никого не пригласили
                            </div>
                          )
                        }
                    </div>
                }
            </React.Fragment>
        );
    };
}

EventsList.propTypes = {
    list: PropTypes.array.isRequired,
    isMainPage: PropTypes.bool,
    handleShowAll: PropTypes.func,
    handleOpenDetails: PropTypes.func.isRequired,
    isEventsLoading: PropTypes.bool.isRequired,
    isPastEventsExist: PropTypes.bool,
};

export default EventsList;

