import React from 'react';
import DefaultPanel from './DefaultPanel';
import {connect} from 'react-redux';
import {
  setActiveEventId,
  clearValues,
  closeNotification,
} from '../../store/events/actions';
import CreateNewEvent from '../CreateNewEvent/CreateNewEvent';
import EventsList from '../EventsList/EventsList';
import {Avatar, Snackbar} from '@vkontakte/vkui';
import Icon28Done from '@vkontakte/icons/dist/28/check_circle_outline';
import Icon28Warning from '@vkontakte/icons/dist/28/error_outline';
import Help from '../Help/Help';

class PanelMain extends DefaultPanel {
  settings() {
    this.title = 'Посещения';
    this.theme = 'white';
    this.hasHeader = false;
  }

  openView = (id) => {
    const {dispatch} = this.props;

    dispatch(setActiveEventId(id));
    this.openPage('view');
  };

  openCreate = () => {
    const {dispatch} = this.props;

    dispatch(clearValues());
    this.openPage('create');
  };

  openAllVisits = () => {
    this.openPage('all-visits');
  };

  openCreateEventNotification() {
    const {dispatch, notificationId} = this.props;

    return (
      <Snackbar
        onClose={() => dispatch(closeNotification())}
        onActionClick={() => this.openView(notificationId)}
        action="Посмотреть"
        before={
          <Avatar
            size={28}
            style={{backgroundColor: 'transparent'}}
          >
            <Icon28Done
              fill="#50c750"
              width={28}
              height={28}
            />
          </Avatar>}
      >
        Приглашение создано
      </Snackbar>
    );
  }

  openEditEventNotification() {
    const {dispatch, notificationId} = this.props;

    return (
      <Snackbar
        onClose={() => dispatch(closeNotification())}
        action="Посмотреть"
        onActionClick={() => this.openView(notificationId)}
        before={
          <Avatar
            size={28}
            style={{backgroundColor: 'transparent'}}
          >
            <Icon28Warning
              fill="#70b2ff"
              width={28}
              height={28}
            />
          </Avatar>
        }
      >
        Приглашение отредактировано
      </Snackbar>
    );
  }

  openCancelEventNotification() {
    const {dispatch} = this.props;

    return (
      <Snackbar
        layout="vertical"
        onClose={() => dispatch(closeNotification())}
        before={
          <Avatar
            size={28}
            style={{backgroundColor: 'transparent'}}
          >
            <Icon28Warning
              fill="#eb4250"
              width={28}
              height={28}
            />
          </Avatar>}
      >
        Приглашение отменено
      </Snackbar>
    );
  }

  renderPanel() {
    const {
      futureEvents = [],
      pastEvents = [],
      isEventsLoading,
      accessToken,
      isEventUpdated,
      isEventCreated,
      isEventCanceled,
    } = this.props;

    return (
      <React.Fragment>
        <CreateNewEvent
          openCreatePanel={this.openCreate}
        />
        <EventsList
          accessToken={accessToken}
          list={futureEvents}
          handleOpenDetails={this.openView}
          handleShowAll={this.openAllVisits}
          isEventsLoading={isEventsLoading}
          isMainPage={true}
          isPastEventsExist={!!pastEvents.length}
        />
        <Help/>
        {isEventCreated && this.openCreateEventNotification()}
        {isEventUpdated && this.openEditEventNotification()}
        {isEventCanceled && this.openCancelEventNotification()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.vk.accessToken,
    futureEvents: state.events.futureEvents,
    pastEvents: state.events.pastEvents,
    isEventsLoaded: state.events.isEventsLoaded,
    isEventsLoading: state.events.isEventsLoading,
    isEventsPastNeedRefresh: state.events.isEventsPastNeedRefresh,
    isEventUpdated: state.events.isEventUpdated,
    isEventCreated: state.events.isEventCreated,
    isEventCanceled: state.events.isEventCanceled,
    notificationId: state.events.notificationId,
  };
};

export default connect(mapStateToProps)(PanelMain);
