import React, {Component} from 'react';
import {Div, Spinner} from '@vkontakte/vkui';

export default class LoaderSpinner extends Component {
  render() {
    return (
      <Div style={{marginBottom: 12}}>
        <Spinner className="spinner" />
      </Div>
    );
  }
}
