import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {platform, IOS, HeaderButton} from '@vkontakte/vkui';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import {goBack, push} from 'react-router-redux';
import {
  closeNotification,
  finishEventsLoading,
  setFutureEvents,
  setPastEvents,
  startEventsLoading,
} from '../store/events/actions';
import {store} from '../../index';
import {getFriendsAvatar} from '../services/vk';
import {getVisits} from '../services/axios';

class BackButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      platform: platform() === IOS ? <Icon28ChevronBack/> : <Icon24Back/>,
    };
  }

  async eventsWithAvatars(token, events) {
    const visitorsId = events
      .filter((visit) => {
        const {visitors} = visit;
        return visitors.length === 1;
      })
      .map((visit) => {
        return visit.visitors[0].vk_id;
      });

    const uniqueVisitorsId = [...new Set(visitorsId)];
    if (uniqueVisitorsId.length > 0) {
      const friendsAvatarData = await getFriendsAvatar(token, uniqueVisitorsId);
      const newEventsList = events.map((event) => {
        const {visitors} = event;
        if (visitors.length === 1) {
          let newEvent = event;

          friendsAvatarData.forEach((avatar) => {
            if (+visitors[0].vk_id === avatar.id) {
              newEvent = {
                ...event,
                visitors: [{
                  ...visitors[0],
                  avatar: avatar.photo_50,
                }],
              };
            }
          });

          return newEvent;
        }

        return event;
      });

      return newEventsList;
    } else {
      return events;
    }
  };

  // TODO refactor

  async getEvents() {
    const {dispatch} = this.props;

    await dispatch(startEventsLoading());
    const token = store.getState().vk.accessToken;

    const getFutureEvents = await dispatch(getVisits('future'));
    const getPastEvents = await dispatch(getVisits('past'));
    if (getPastEvents) {
      const newPastEvents = await this.eventsWithAvatars(token, getPastEvents.data);

      await dispatch(setPastEvents(newPastEvents));
    } else {
      await dispatch(setPastEvents([]));
    }
    if (getFutureEvents) {
      const newFutureEvents = await this.eventsWithAvatars(token, getFutureEvents.data);

      await dispatch(setFutureEvents(newFutureEvents));
    } else {
      await dispatch(setFutureEvents([]));
    }

    await dispatch(finishEventsLoading());
  };

  async openBack() {
    const {backPage, dispatch, customBack} = this.props;
    const globalStore = store.getState();

    if (globalStore.router.location.pathname === '/view') {
      await this.getEvents();
    }

    if (backPage) {
      dispatch(push(`/${backPage}`));
    } else {
      dispatch(goBack());
    }

    // TODO выпилить этот костыль
    dispatch(closeNotification());

    customBack();
  }

  render() {
    return (
      <HeaderButton onClick={() => this.openBack()}>
        {this.state.platform}
      </HeaderButton>
    );
  };
}

export default BackButton;

BackButton.propTypes = {
  backPage: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  customBack: PropTypes.func.isRequired,
};
