import * as types from './actionTypes';

const initialState = {
  friends: [],
  isFriendsLoading: false,
};

export const friends = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FRIENDS:
      return {
        ...state,
        friends: action.friends,
      };

    case types.SET_FRIENDS_LOADING:
      return {
        ...state,
        isFriendsLoading: true,
      };

    case types.SET_FRIENDS_LOADED:
      return {
        ...state,
        isFriendsLoading: false,
      };

    default:
      return state;
  }
};
