import * as types from './actionTypes';

export const addEvent = (event) => (
  {
    type: types.ADD_EVENT,
    event,
    isEventCreated: true,
    notificationId: event.id,
  }
);

export const setEvent = (event) => (
  {
    type: types.SET_EVENT,
    event,
  }
);

export const updateEvent = (event) => (
  {
    type: types.UPDATE_EVENT,
    event,
    isEventUpdated: true,
    notificationId: event.id,
  }
);

export const closeNotification = () => (
  {
    type: types.CLOSE_NOTIFICATION,
  }
);

export const deleteEvent = (deleteId) => (
  {
    type: types.DELETE_EVENT,
    deleteId,
  }
);

export const setFutureEvents = (futureEvents) => (
  {
    type: types.SET_EVENTS_FUTURE,
    futureEvents,
  }
);

export const setPastEvents = (pastEvents) => (
  {
    type: types.SET_EVENTS_PAST,
    pastEvents,
  }
);

export const startEventsLoading = () => (
  {
    type: types.START_EVENTS_LOADING,
  }
);

export const finishEventsLoading = () => (
  {
    type: types.FINISH_EVENTS_LOADING,
  }
);

export const setActiveEventId = (activeId) => (
  {
    type: types.SET_ACTIVE_EVENT_ID,
    activeId,
  }
);

export const setVisitors = (visitors) => (
  {
    type: types.SET_VISITORS,
    visitors,
  }
);

export const setAvatars = (avatars) => (
  {
    type: types.SET_AVATARS,
    avatars,
  }
);

export const setOffice = (office) => (
  {
    type: types.SET_OFFICE,
    office,
  }
);

export const setDate = (visit_date) => (
  {
    type: types.SET_DATE,
    visit_date,
  }
);

export const setPurpose = (purpose) => (
  {
    type: types.SET_PURPOSE,
    purpose,
  }
);

export const setTitle = (title) => (
  {
    type: types.SET_TITLE,
    title,
  }
);

export const setPin = (visit_code) => (
  {
    type: types.SET_PIN,
    visit_code,
  }
);

export const setErrDate = (visit_date) => (
  {
    type: types.SET_ERR_DATE,
    visit_date,
  }
);

export const clearValues = () => (
  {
    type: types.CLEAR_VALUES,
  }
);
