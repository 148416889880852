import {getAuthToken, getUserInfo} from './vk';
import {getJwt} from './axios';
import {setAppId, setJwt} from '../store/vk/actions';
import {getQueryParams} from '../store/query/actions';
import {setIsDesktop} from '../store/globals/actions';
import queryString from 'query-string';

export const authUser = () => async (dispatch) => {
  // Вытаскиваем данные из url и кладем их в store
  const url = window.location.href;
  const queryParams = queryString.parse(url);
  const entranceData = await dispatch(getQueryParams(queryParams));

  const userId = entranceData.params.vk_user_id;
  const appId = entranceData.params.vk_app_id;

  dispatch(setAppId(appId));

  // Проверяем дектоп версия запущена или нет
  const desktop = (new URL(url)).searchParams.get('vk_platform');
  if (desktop === 'desktop_web' || desktop === 'mobile_web') {
    dispatch(setIsDesktop());
  }

  // Получаем ВК информацию пользователя из приложения
  const userInfo = await dispatch(getUserInfo());

  // Получаем токен ВК
  const token = await dispatch(getAuthToken());

  // Если с данными беда, то на повторное получение
  if (userInfo === '' || token === '') {
    return {
      result: 'fail',
      error: userInfo === '' ? 'userInfo' : 'token',
    };
  }

  // Получаем JWT
  const jwtResult = await getJwt({id: userId, token: token});

  if (jwtResult.result === 'success') {
    await dispatch(setJwt(jwtResult.token));
  } else {
    return jwtResult;
  }

  return {result: 'success'};
};
