export const ADD_EVENT = 'ADD_EVENT';
export const SET_EVENT = 'SET_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const SET_EVENTS_PAST = 'SET_EVENTS_PAST';
export const SET_EVENTS_FUTURE = 'SET_EVENTS_FUTURE';
export const START_EVENTS_LOADING = 'START_EVENTS_LOADING';
export const FINISH_EVENTS_LOADING = 'FINISH_EVENTS_LOADING';
export const SET_ACTIVE_EVENT_ID = 'SET_ACTIVE_EVENT_ID';
export const SET_VISITORS = 'SET_VISITORS';
export const SET_AVATARS = 'SET_AVATARS';
export const SET_OFFICE = 'SET_OFFICE';
export const SET_DATE = 'SET_DATE';
export const SET_PURPOSE = 'SET_PURPOSE';
export const SET_TITLE = 'SET_TITLE';
export const SET_PIN = 'SET_PIN';
export const SET_ERR_LINK = 'SET_ERR_LINK';
export const SET_ERR_NAME = 'SET_ERR_NAME';
export const SET_ERR_DATE = 'SET_ERR_DATE';
export const SET_ERR_PURPOSE = 'SET_ERR_PURPOSE';
export const CLEAR_VALUES = 'CLEAR_VALUES';
