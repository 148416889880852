import * as types from './actionTypes';

export const setAuthTokenSuccess = (token) => (
  {
    type: types.VK_ACCESS_TOKEN_GET_SUCCESS,
    accessToken: token,
  }
);

export const setAuthTokenFail = () => (
  {
    type: types.VK_ACCESS_TOKEN_GET_FAIL,
  }
);

export const setInsets = (insets) => (
  {
    type: types.VK_INSETS_GET_SUCCESS,
    insets: insets,
  }
);

export const setVkUser = (vkUser) => (
  {
    type: types.VK_USER_GET_SUCCESS,
    vkUser: vkUser,
  }
);

export const setVkUserFail = () => (
  {
    type: types.VK_USER_GET_FAIL,
    vkUser: null,
  }
);

export const setApiStartLoading = () => (
  {
    type: types.VK_SET_API_START_LOADING,
  }
);

export const setApiEndLoading = () => (
  {
    type: types.VK_SET_API_END_LOADING,
  }
);

export const setJwt = (jwt) => (
  {
    type: types.VK_SET_JWT,
    jwt,
  }
);

export const setAppId = (appId) => (
  {
    type: types.VK_SET_APP_ID,
    appId,
  }
);

export const authUserStart = () => (
  {
    type: types.VK_AUTH_USER_START,
  }
);

export const authUserFinish = () => (
  {
    type: types.VK_AUTH_USER_FINISH,
  }
);
