import React from 'react';
import {Div, Button} from '@vkontakte/vkui';
import DefaultPanel from '../DefaultPanel';
import {connect} from 'react-redux';
import {clearValues, setActiveEventId, setEvent} from '../../../store/events/actions';
import {closePopout, setPopoutSpinner} from '../../../store/globals/actions';
import {getVisit} from '../../../services/axios';
import {rusDate} from '../../../services/helpers';
import {qrSvg, prettifyVisitCode} from '../../../services/helpers';
import {EDIT} from '../../../constants/path';
import {
  PENDING,
  PAST,
  SKIPPED,
  CANCELLED,
} from '../../../constants/status';
import {ZINGER, RED_BRIDGE} from '../../../constants/office';
import './PanelEventView.css';

class PanelEventView extends DefaultPanel {
  settings() {
    this.title = 'Приглашение';
    this.hasBack = true;
    this.theme = 'white';
  }

  renderStatusTitle(status) {
    switch (status) {
      case PENDING.status:
        return PENDING.title;
      case PAST.status:
        return PAST.title;
      case SKIPPED.status:
        return SKIPPED.title;
      case CANCELLED.status:
        return CANCELLED.title;
      default:
        return console.error(`Wrong status: ${status}`);
    }
  }

  renderOfficeTitle(office) {
    switch (office) {
      case ZINGER.value:
        return ZINGER.title;
      case RED_BRIDGE.value:
        return RED_BRIDGE.title;
      default:
        return console.error(`Wrong office: ${office}`);
    }
  }

  pComponentWillMount = async () => {
    const {dispatch} = this.props;
    dispatch(setPopoutSpinner());

    const getVisitResult = await dispatch(getVisit(this.props.activeId));

    dispatch(closePopout());

    if (getVisitResult.result === 'success') {
      dispatch(setEvent(getVisitResult.data));
    } else {
        this.openPage('wrong-user');
    }
  };

  openEdit = async (id) => {
    const {dispatch} = this.props;

    dispatch(setPopoutSpinner());

    const getVisitResult = await dispatch(getVisit(id));

    if (getVisitResult.data.status === PENDING.status) {
      dispatch(setActiveEventId(id));
      this.openPage(EDIT);
    } else {
      dispatch(setActiveEventId(''));
      this.openPage('main');
    }

    dispatch(closePopout());
  };

  openCreate = (id) => {
    const {dispatch} = this.props;

    if (id) {
      dispatch(setActiveEventId(id));
    } else {
      dispatch(clearValues());
    }

    this.openPage('create');
  };

  renderNames() {
    const {
      eventData: {
        visitors,
      },
    } = this.props;

    if (visitors.length === 1) {
      const name = visitors[0].name;

      if (name.length < 20) {
        return (
           <div className="PanelEventView__shortName">
             {visitors[0].name}
           </div>
        );
      } else {
        return (
          <div className="PanelEventView__longName">
             {visitors[0].name}
          </div>
        );
      }
    } else {
      return (
        <div className="PanelEventView__names">
          {visitors.map((visitor) => {
            return (
                <p key={visitor.name} className="PanelEventView__name">
                  {visitor.name}
                </p>
              );
          })}
        </div>
      );
    }
  }

  renderPanel() {
    const {
      eventData: {
        office,
        visit_date,
        status,
        visit_code,
        id,
        purpose,
      },
    } = this.props;

    if (!status) {
      return;
    }

    const isPendingEvent = status === PENDING.status;

    return (
        <Div>
          <Div
            className="PanelEventView__qr"
            dangerouslySetInnerHTML={qrSvg(visit_code, 256)}
          />
          <div className="PanelEventView__info">
            <div className="PanelEventView__infoItem">
              <div className="PanelEventView__infoTitle">
                Код
              </div>
              <div className="PanelEventView__code">
                {prettifyVisitCode(visit_code)}
              </div>
            </div>
            {status && !isPendingEvent &&
              <div className="PanelEventView__infoItem">
                <div className="PanelEventView__infoTitle">
                  Статус
                </div>
                <div className="PanelEventView__status">
                  {this.renderStatusTitle(status)}
                </div>
              </div>
            }
          </div>
          <div className="PanelEventView__visitors">
            {this.renderNames()}
          </div>
          {purpose &&
            <div className="PanelEventView__purpose">
              {purpose}
            </div>
          }
          {office &&
            <div className="PanelEventView__office">
              {this.renderOfficeTitle(office)}
            </div>
          }
          <div className="PanelEventView__visitDate">{rusDate(visit_date)}</div>
          {isPendingEvent &&
            <div className="PanelEventView__buttonContainer">
              <Button
                size="l"
                level="secondary"
                onClick={() => this.openEdit(id)}
              >
                Редактировать
              </Button>
            </div>
          }
          {!isPendingEvent &&
            <div className="PanelEventView__buttonContainer">
              <Button
                size="l"
                level="secondary"
                onClick={() => this.openCreate(id)}
              >
                Повторить посещение
              </Button>
            </div>
          }
        </Div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    eventData: state.events.event,
    activeId: state.events.activeId,
  };
};

export default connect(mapStateToProps)(PanelEventView);
