import moment from 'moment';
import qr from '@vkontakte/vk-qr';

// Преобразовать первую букву в заглавную
export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Случайное в промежутке
export const randInt = (from, to) => {
  return Math.floor(Math.random() * (to - from + 1)) + from;
};

// DATE в RUS  DATE
export const rusDate = (date) => {
  return moment(date).format('DD.MM.YYYY');
};

// Если дата меньше сегодня
export const dateLess = (date) => {
  return moment(date).isBefore(moment().startOf('day'));
};

// Если дата больше чем через год
export const dateMoreThanInYear = (date) => {
  return !moment(date).isBefore(moment().add(1, 'year'));
};

export const isSafari = (isDesktop) => {
  return (
    navigator.userAgent.indexOf('Safari') !== -1
    && navigator.userAgent.indexOf('Chrome') === -1
    && isDesktop
  );
};

export const qrSvg = (visit_code, size = 256) => {
  const qrSvg = qr.createQR(
    visit_code.toString(),
    {
      qrSize: size,
      isShowLogo: true,
      isShowBackground: true,
      backgroundColor: '#ffffff',
      logoColor: '#000000',
    }
  );

  return {__html: qrSvg};
};

export const prettifyDate = (date, separator) => {
  return date.split(separator).reverse().join('.');
};

export const prettifyVisitCode = (code) => {
  const codeLength = code.toString().length;

  switch (codeLength) {
    case 1:
      return `000${code}`;
    case 2:
      return `00${code}`;
    case 3:
      return `0${code}`;
    default:
      return code;
  }
};
