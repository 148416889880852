import * as types from './actionTypes';

const initialState = {
  vkUser: undefined,
  firstTryGetAccessToken: false,
  accessToken: undefined,
  insets: {
    top: '100px',
    bottom: 0,
    left: 0,
    right: 0,
  },
  themeScheme: '',
  isApiLoading: false,
  jwt: '',
  appId: 0,
  isAuthUser: false,
};

export function vkReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.VK_ACCESS_TOKEN_GET_SUCCESS:
      return {
        ...state,
        accessToken: action.accessToken,
        firstTryGetAccessToken: true,
        isApiLoading: false,
      };

    case types.VK_ACCESS_TOKEN_GET_FAIL:
      return {
        ...state,
        accessToken: undefined,
        firstTryGetAccessToken: true,
        isApiLoading: false,
      };

    case types.VK_INSETS_GET_SUCCESS:
      return {
        ...state,
        insets: action.insets,
      };

    case types.VK_AUTH_USER_START:
      return {
        ...state,
        isAuthUser: false,
      };

    case types.VK_AUTH_USER_FINISH:
      return {
        ...state,
        isAuthUser: true,
      };

    case types.VK_USER_GET_SUCCESS:
      return {
        ...state,
        vkUser: action.vkUser,
      };

    case types.VK_USER_GET_FAIL:
      return {
        ...state,
        vkUser: null,
      };

    case types.VK_SET_API_START_LOADING:
      return {
        ...state,
        isApiLoading: true,
      };

    case types.VK_SET_API_END_LOADING:
      return {
        ...state,
        isApiLoading: false,
      };

    case types.VK_SET_JWT:
      return {
        ...state,
        jwt: action.jwt,
      };

    case types.VK_SET_APP_ID:
      return {
        ...state,
        appId: action.appId,
      };

    default:
      return state;
  }
}
