import React from 'react';
import DefaultPanel from './DefaultPanel';
import Icon56DoNotDisturbOutline from '@vkontakte/icons/dist/56/do_not_disturb_outline';
import {Placeholder} from '@vkontakte/vkui';

export default class PanelWrongUser extends DefaultPanel {
  settings() {
    this.hasHeader = false;
    this.theme = 'white';
  }

  renderPanel() {
    return (
      <Placeholder
        icon={<Icon56DoNotDisturbOutline />}
      >
        Доступ запрещен
      </Placeholder>
    );
  }
}
