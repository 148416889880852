import React from 'react';
import {Search} from '@vkontakte/vkui';
import DefaultPanel from '../DefaultPanel';
import {connect} from 'react-redux';
import {getVisits} from '../../../services/axios';
import {
  finishEventsLoading,
  setActiveEventId,
  setPastEvents,
  startEventsLoading,
} from '../../../store/events/actions';
import LoaderSpinner from '../../LoaderSpinner';
import {VIEW} from '../../../constants/path';
import EventsList from '../../EventsList/EventsList';
import './PanelEvents.css';
import {getFriendsAvatar} from '../../../services/vk';

class PanelEvents extends DefaultPanel {
  state = {
    search: '',
  };

  changeSearch = (search) => {
    this.setState({search});
  };

  settings() {
    this.title = 'Все приглашения';
    this.hasBack = true;
    this.backPage = 'main';
  }

  openView = (id) => {
    this.props.dispatch(setActiveEventId(id));
    this.openPage(VIEW);
  };

  eventsWithAvatars = async (token, events) => {
    const visitorsId = events
      .filter((visit) => {
        const {visitors} = visit;
        return visitors.length === 1;
      })
      .map((visit) => {
        return visit.visitors[0].vk_id;
      });

    const uniqueVisitorsId = [...new Set(visitorsId)];
    if (uniqueVisitorsId.length > 0) {
      const friendsAvatarData = await getFriendsAvatar(token, uniqueVisitorsId);
      const newEventsList = events.map((event) => {
        const {visitors} = event;
        if (visitors.length === 1) {
          let newEvent = event;

          friendsAvatarData.forEach((avatar) => {
            if (+visitors[0].vk_id === avatar.id) {
              newEvent = {
                ...event,
                visitors: [{
                  ...visitors[0],
                  avatar: avatar.photo_50,
                }],
              };
            }
          });

          return newEvent;
        }

        return event;
      });

      return newEventsList;
    } else {
      return events;
    }
  };

  pComponentWillMount = async () => {
    const {
      accessToken,
      isEventsPastNeedRefresh,
      dispatch,
    } = this.props;

    if (isEventsPastNeedRefresh) {
      dispatch(startEventsLoading());

      let getVisitsResult = await dispatch(getVisits('past'));
      if (getVisitsResult.result === 'success') {
        const newPastEvents = await this.eventsWithAvatars(accessToken, getVisitsResult.data);

        dispatch(setPastEvents(newPastEvents));
      } else {
        dispatch(setPastEvents([]));
      }
      dispatch(finishEventsLoading());
    }
  };

  renderPastList = () => {
    const {
      accessToken,
      isEventsLoading,
    } = this.props;

    return (
      <EventsList
        accessToken={accessToken}
        list={this.pastEvents}
        handleOpenDetails={this.openView}
        handleShowAll={this.openAllVisits}
        isEventsLoading={isEventsLoading}
      />
    );
  };

  renderFutureList = () => {
    const {
      accessToken,
      isEventsLoading,
    } = this.props;
    return (
      <EventsList
        accessToken={accessToken}
        list={this.futureEvents}
        handleOpenDetails={this.openView}
        handleShowAll={this.openAllVisits}
        isEventsLoading={isEventsLoading}
      />
    );
  };

  get futureEvents() {
    const {futureEvents} = this.props;
    const {search} = this.state;

    const searchValue = search.toLowerCase();
    return (
      futureEvents.filter(({visitors}) => {
        let isMatch = false;

        visitors.forEach((visitor) => {
          isMatch = visitor.name.toLowerCase().indexOf(searchValue) > -1;
        });

        return isMatch;
      }
    ));
  }

  get pastEvents() {
    const {pastEvents} = this.props;
    const {search} = this.state;

    const searchValue = search.toLowerCase();
    return (
      pastEvents.filter(({visitors}) => {
        let isMatch = false;

        visitors.forEach((visitor) => {
          isMatch = visitor.name.toLowerCase().indexOf(searchValue) > -1;
        });

        return isMatch;
      }
    ));
  }

  renderFutureEvents() {
    const {isEventsLoading} = this.props;

    return (
      <React.Fragment>
        <div className="PanelEvents__eventTitle">
          Предстоящие
        </div>
        {this.renderFutureList()}
        {isEventsLoading && <LoaderSpinner/>}
      </React.Fragment>
    );
  }

  renderPastEvents() {
    const {isEventsLoading} = this.props;

    return (
      <React.Fragment>
        <div className="PanelEvents__eventTitle">
          Прошедшие
        </div>
        {this.renderPastList()}
        {isEventsLoading && <LoaderSpinner/>}
      </React.Fragment>
    );
  }

  renderPanel() {
    const {search} = this.state;
    const isFutureEvents = this.futureEvents.length !== 0;
    const isPastEvents = this.pastEvents.length !== 0;

    return (
      <React.Fragment>
        <Search
          value={search}
          onChange={this.changeSearch}
        />
        <div className="PanelEvents__list">
          {isFutureEvents && this.renderFutureEvents()}
          {isPastEvents && this.renderPastEvents()}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.vk.accessToken,
    pastEvents: state.events.pastEvents,
    futureEvents: state.events.futureEvents,
    isEventsLoaded: state.events.isEventsLoaded,
    isEventsLoading: state.events.isEventsLoading,
    isEventsPastNeedRefresh: state.events.isEventsPastNeedRefresh,
  };
};

export default connect(mapStateToProps)(PanelEvents);
