import React from 'react';
import DefaultPanel from './DefaultPanel';
import {connect} from 'react-redux';
import {
  addEvent,
  deleteEvent, setActiveEventId,
  setAvatars,
  setDate,
  setErrDate,
  setEvent,
  setOffice,
  setPin,
  setPurpose, setTitle,
  setVisitors,
  updateEvent,
} from '../../store/events/actions';
import {dateLess, dateMoreThanInYear, isSafari} from '../../services/helpers';
import {createVisit, deleteVisit, editVisit, getVisit} from '../../services/axios';
import {closePopout, setPopout, setPopoutSpinner} from '../../store/globals/actions';
import {getFriendFromList, getFriendsAvatar} from '../../services/vk';
import DeleteConfirm from '../DeleteConfirm';
import 'react-datepicker/dist/react-datepicker.css';
import {registerLocale} from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import moment from 'moment';
import PanelEventForm from './PanelEventForm/PanelEventForm';
import {PENDING} from '../../constants/status';
import {CREATE_PATH} from '../../constants/path';

registerLocale('ru', ru);

class PanelEventCreateEdit extends DefaultPanel {
  refFirstError;

  settings() {
    this.title = 'Приглашение';
    this.backPage = 'main';
    this.theme = 'white';
    this.hasBack = true;
  }

  changeHandler = (e) => {
    const {name, value} = e.currentTarget;
    const {dispatch} = this.props;

    switch (name) {
      case 'title':
        if (value.length > 25) {
          return;
        }
        dispatch(setTitle(value));
        break;

      case 'office':
        dispatch(setOffice(value));
        break;

      case 'date':
        dispatch(setDate(value));

        if (value !== '') {
          dispatch(setErrDate(false));
        }

        break;

      case 'purpose':
        dispatch(setPurpose(value));
        break;

      default:
        return null;
    }
  };

  checkErrors = () => {
    const {eventData, dispatch} = this.props;
    let isErrors = false;
    let regex = /^\s*$/;
    this.refFirstError = undefined;

    dateMoreThanInYear(eventData.visit_date);

    // eslint-disable-next-line max-len
    if (eventData.visit_date.match(regex) || dateLess(eventData.visit_date) || dateMoreThanInYear(eventData.visit_date)) {
      isErrors = true;
      dispatch(setErrDate(true));
    }

    return isErrors;
  };

  async getDataWithAvatar(accessToken, data) {
    const {visitors} = data;

    if (visitors.length === 1) {
      if (visitors[0].vk_id) {
        const friendAvatarData = await getFriendsAvatar(accessToken, visitors[0].vk_id);
        return {
          ...data,
          visitors: [{
            ...visitors[0],
            avatar: friendAvatarData[0].photo_50,
          }],
        };
      }
    }

    return data;
  }

  create = async () => {
    const {
      accessToken,
      dispatch,
      eventData: {
        visit_date,
        purpose,
        visitors,
        title,
      },
    } = this.props;

    const isErrors = this.checkErrors();

    if (isErrors) {
      return;
    }

    dispatch(setPopoutSpinner());

    let format_date = moment(visit_date).format('YYYY-MM-DD');
    const params = {
      office: 'zinger',
      title: title,
      visit_date: format_date,
      purpose: purpose,
      visitors: visitors,
    };

    const createResult = await dispatch(createVisit(params));

    dispatch(closePopout());

    if (createResult.result === 'success') {
      const eventData = await this.getDataWithAvatar(accessToken, createResult.data);

      dispatch(addEvent(eventData));
      dispatch(setPin(eventData.visit_code));
      this.openPage('main');
    } else {
      this.openPage('create-fail');
    }
  };

  edit = async () => {
    const {eventData, dispatch, activeId, accessToken} = this.props;
    const isErrors = this.checkErrors();

    if (!isErrors) {
      dispatch(setPopoutSpinner());
      const params = {
        office: eventData.office,
        visit_date: eventData.visit_date,
        purpose: eventData.purpose,
        visitors: eventData.visitors,
        title: eventData.title,
      };
      const editResult = await dispatch(editVisit(activeId, params));

      dispatch(closePopout());

      if (editResult.result === 'success') {
        const eventData = await this.getDataWithAvatar(accessToken, editResult.data);

        dispatch(updateEvent(eventData));
        this.openPage('main');
      } else {
        this.openPage('edit-fail');
      }
    }
  };

  delete = async () => {
    const {dispatch} = this.props;

    dispatch(setPopout(
      <DeleteConfirm
        deleteAction={this.deleteConfirm}
        dispatch={dispatch}
      />,
    ));
  };

  deleteConfirm = async () => {
    const {dispatch, activeId} = this.props;

    dispatch(setPopoutSpinner());

    const deleteResult = await dispatch(deleteVisit(activeId));

    dispatch(closePopout());

    if (deleteResult.result === 'success') {
      dispatch(deleteEvent(activeId));
      this.openPage('main');
    } else {
      this.openPage('delete-fail');
    }
  };

  pComponentWillMount = async () => {
    const {
      accessToken,
      pathname,
      dispatch,
      activeId,
      isDesktop,
      visitors,
      avatars = [],
    } = this.props;

    if (pathname === CREATE_PATH) {
      dispatch(setTitle('Несколько гостей'));
    }

    if (pathname === '/edit') {
      dispatch(setPopoutSpinner());

      const getVisitResult = await dispatch(getVisit(activeId));

      if (getVisitResult.data.status !== PENDING.status) {
        dispatch(setActiveEventId(''));
        this.openPage('main');
      }

      dispatch(closePopout());

      if (getVisitResult.result === 'success') {
        const event = getVisitResult.data;
        const visitorsId = event.visitors
          .map((visitor) => {
            return visitor.vk_id;
          });

        const friendAvatarData = await getFriendsAvatar(accessToken, visitorsId);

        const listOfAvatars = friendAvatarData.map((data) => {
          return {
            vk_id: data.id,
            avatar: data.photo_50,
          };
        });

        if (isSafari(isDesktop)) {
          getVisitResult.data.date = moment(getVisitResult.data.date, 'YYYY-MM-DD').toDate();
        }

        // TODO пофиксить это, фикс был сделан быстро
        // нужно вообще убрать разделение посещений и аватарок
        // на две сущности и объедить их

        const visitsData = {
          ...getVisitResult.data,
          visitors,
          avatars,
        };

        const avatarsData = [
          ...listOfAvatars,
          ...avatars,
        ];

        dispatch(setEvent(visitsData));
        dispatch(setAvatars(avatarsData));
      } else {
        this.openPage('next');
      }
    }
  };

  openFriendsList = async () => {
    const {
      isDesktop,
      dispatch,
      eventData: {
        visitors,
        avatars,
      },
    } = this.props;
    if (isDesktop) {
      this.openPage('friends');
    } else {
      let getFriendResult = await getFriendFromList();
      if (getFriendResult.result === 'success') {
        let friendAlreadyAdded = false;

        if (visitors.length !== 0) {
          friendAlreadyAdded = !!visitors.find((visitor) => {
            if (+visitor.vk_id === +getFriendResult.data[0].id) {
              return visitor.vk_id;
            }
          });
        }

        // если друг уже добавлен - ничего не делаем
        if (friendAlreadyAdded) {
          return;
        }

        const listOfVisitors = [...visitors];
        const listOfAvatars = [...avatars];
        listOfVisitors.push({
          vk_id: getFriendResult.data[0].id,
          name: `${getFriendResult.data[0].first_name} ${getFriendResult.data[0].last_name}`,
        });
        listOfAvatars.push({
          vk_id: getFriendResult.data[0].id,
          avatar: getFriendResult.data[0].photo_200,
        });
        dispatch(setVisitors(listOfVisitors));
        dispatch(setAvatars(listOfAvatars));
      }
    }
  };

  changeDateHandler = (date) => {
    const {dispatch} = this.props;
    if (date === null) {
      date = '';
    }
    dispatch(setDate(date));
    if (date !== '') {
      dispatch(setErrDate(false));
    }
  };

  renderPanel() {
    const {
      pathname,
      eventData,
      eventErrors,
      isDesktop,
      dispatch,
    } = this.props;

    return (
      <PanelEventForm
        dispatch={dispatch}
        pathname={pathname}
        eventData={eventData}
        eventErrors={eventErrors}
        isSafari={isSafari(isDesktop)}
        changeHandler={this.changeHandler}
        createEvent={this.create}
        editEvent={this.edit}
        deleteEvent={this.delete}
        changeDateHandler={this.changeDateHandler}
        openFriendsList={this.openFriendsList}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    eventData: state.events.event,
    visitors: state.events.event.visitors,
    avatars: state.events.event.avatars,
    eventErrors: state.events.errors,
    activeId: state.events.activeId,
    pathname: state.router.location.pathname,
    accessToken: state.vk.accessToken,
    isDesktop: state.globals.isDesktop,
  };
};

export default connect(mapStateToProps)(PanelEventCreateEdit);
