import axios from 'axios';
import {store} from '../../index';
import {authUser} from './auth';

// Получить посещения
export const getVisits = (time) => (dispatch) => {
  return dispatch(axiosRequest('get', 'visits', {ordering: 'visit_date', time}));
};

// Получить одно посещение
export const getVisit = (id) => (dispatch) => {
  return dispatch(axiosRequest('get', `visits/${id}`, {}));
};

// Создать посещения
export const createVisit = ({
  office,
  visit_date,
  purpose,
  title,
  visitors,
}) => (dispatch) => {
  return dispatch(axiosRequest(
    'post',
    'visits',
    {
      office,
      visit_date,
      purpose,
      title,
      visitors,
    }));
};

// Редактировать посещения
export const editVisit = (
    id,
    {
      office,
      visit_date,
      purpose,
      title,
      visitors,
    }
  ) => (dispatch) => {
  return dispatch(axiosRequest(
    'patch',
    `visits/${id}`,
    {
      office,
      visit_date,
      purpose,
      title,
      visitors,
    }
  ));
};

// Удалить посещение
export const deleteVisit = (id) => (dispatch) => {
  return dispatch(axiosRequest('delete', `visits/${id}`, {}));
};

// Получить JWT
export const getJwt = (postData) => {
  const successFunc = (response) => {
    return {result: 'success', token: response.data.token};
  };

  const failFunc = (error) => {
    if (error.response && error.response.status === 400) {
      return {result: 'fail', error: 'jwt'};
    } else {
      return {result: 'fail', error: 'unknown'};
    }
  };

  let preparedPostData = new FormData();
  Object.keys(postData).map((key) => (
    preparedPostData.append(key, postData[key])
  ));

  return axios({
    method: 'post',
    url: process.env.SITE_BASE_URL + '/token-auth/',
    data: preparedPostData,
    header: {ContentType: 'application/x-www-form-urlencoded'},
  }).then((result) => successFunc(result)).catch((error) => failFunc(error));
};

// Шаблон запроса
export const axiosRequest = (method, url, postData) => async (dispatch) => {
  const successFunc = (response) => {
    return {result: 'success', data: response.data};
  };

  const failFunc = (error) => {
    if (error.response && error.response.status === 401) {
      return {result: 'fail', error: '401 Unauthorized'};
    } else {
      return {result: 'fail', error: 'unknown'};
    }
  };

  let params = null;
  if (method === 'get') {
    params = {...postData};
  }

  // Делаем запрос
  const request = () => axios({
    method: method,
    url: process.env.SITE_BASE_URL + '/' + url + '/',
    data: postData,
    params: params,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'JWT ' + store.getState().vk.jwt,
    },
  })
    .then((result) => successFunc(result))
    .catch((error) => {
      return failFunc(error);
    });

  let requestResult = await request();

  if (requestResult.result === 'fail') {
    await dispatch(authUser());
    requestResult = await request();
  }

  return requestResult;
};
