import * as types from './actionTypes';

const initialState = {
  futureEvents: [],
  pastEvents: [],
  isEventsLoading: true,
  isEventsLoaded: false,
  isEventsPastLoaded: false,
  isEventsPastNeedRefresh: true,
  isEventUpdated: false,
  isEventCreated: false,
  isEventCanceled: false,
  activeId: '',
  event: {
    office: '',
    visit_date: '',
    purpose: '',
    visit_code: '',
    title: '',
    visitors: [],
    avatars: [],
  },
  errors: {
    vk_id: false,
    name: false,
  },
};

export const events = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_EVENT:
      return {
        ...state,
        futureEvents: [action.event, ...state.futureEvents],
        isEventCreated: true,
        notificationId: action.notificationId,
      };

    case types.SET_EVENT:
      return {
        ...state,
        event: action.event,
      };

    case types.UPDATE_EVENT:
      let updatedEvents = [];
      state.futureEvents.forEach((event) => {
        if (event.id === action.event.id) updatedEvents.push(action.event);
        else updatedEvents.push(event);
      });
      return {
        ...state,
        futureEvents: updatedEvents,
        isEventUpdated: true,
        notificationId: action.notificationId,
      };

    case types.DELETE_EVENT:
      return {
        ...state,
        futureEvents: state.futureEvents.filter((event) => event.id !== action.deleteId),
        isEventsPastNeedRefresh: true,
        isEventCanceled: true,
      };

    case types.SET_EVENTS_FUTURE:
      return {
        ...state,
        futureEvents: action.futureEvents,
        isEventsLoaded: true,
      };

    case types.SET_EVENTS_PAST:
      return {
        ...state,
        pastEvents: action.pastEvents,
        isEventsPastLoaded: true,
        isEventsPastNeedRefresh: false,
      };

    case types.START_EVENTS_LOADING:
      return {
        ...state,
        isEventsLoading: true,
      };

    case types.FINISH_EVENTS_LOADING:
      return {
        ...state,
        isEventsLoading: false,
      };

    case types.CLOSE_NOTIFICATION:
      return {
        ...state,
        isEventUpdated: false,
        isEventCreated: false,
        isEventCanceled: false,
        notificationId: null,
      };

    case types.SET_ACTIVE_EVENT_ID:
      return {
        ...state,
        activeId: action.activeId,
      };

    case types.SET_VISITORS:
      return {
        ...state,
        event: {
          ...state.event,
          visitors: action.visitors,
        },
      };

    case types.SET_AVATARS:
      return {
        ...state,
        event: {
          ...state.event,
          avatars: action.avatars,
        },
      };

    case types.SET_OFFICE:
      return {
        ...state,
        event: {
          ...state.event,
          office: action.office,
        },
      };

    case types.SET_DATE:
      return {
        ...state,
        event: {
          ...state.event,
          visit_date: action.visit_date,
        },
      };

    case types.SET_PURPOSE:
      return {
        ...state,
        event: {
          ...state.event,
          purpose: action.purpose,
        },
      };

    case types.SET_PIN:
      return {
        ...state,
        event: {
          ...state.event,
          visit_code: action.visit_code,
        },
      };

    case types.SET_TITLE:
      return {
        ...state,
        event: {
          ...state.event,
          title: action.title,
        },
      };

    case types.SET_ERR_LINK:
      return {
        ...state,
        errors: {
          ...state.errors,
          vk_id: action.vk_id,
        },
      };

    case types.SET_ERR_NAME:
      return {
        ...state,
        errors: {
          ...state.errors,
          name: action.name,
        },
      };

    case types.SET_ERR_DATE:
      return {
        ...state,
        errors: {
          ...state.errors,
          visit_date: action.visit_date,
        },
      };

    case types.SET_ERR_PURPOSE:
      return {
        ...state,
        errors: {
          ...state.errors,
          purpose: action.purpose,
        },
      };

    case types.CLEAR_VALUES:
      return {
        ...state,
        activeId: 0,
        event: {
          office: '',
          title: '',
          visit_date: '',
          purpose: '',
          visit_code: '',
          visitors: [],
          avatars: [],
        },
        errors: {
          vk_id: false,
          name: false,
        },
      };

    default:
      return state;
  }
};
