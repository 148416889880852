import React, {useState} from 'react';
import {closeModal} from '../store/globals/actions';
import {Input, ModalCard, ModalRoot} from '@vkontakte/vkui';
import PropTypes from 'prop-types';

const AddGuestManually = ({dispatch, eventErrors, addGuest}) => {
  const [name, changeName] = useState('');

  const closeModalFunc = () => {
    dispatch(closeModal());
  };

  const onChangeNameHandler = (e) => {
    const {value} = e.currentTarget;

    if (value.length > 40) {
      return;
    }

    changeName(value);
  };

  return (
    <ModalRoot activeModal="modal_card_guests">
      <ModalCard
        id={'modal_card_guests'}
        onClose={closeModalFunc}
        title="Введите имя гостя"
        actions={[
          {
            title: 'Добавить',
            type: 'primary',
            action: () => {
              addGuest(name);
              dispatch(closeModal());
            },
          },
        ]}
      >
        <Input
          autoFocus
          type="text"
          top="Имя и фамилия"
          placeholder="Имя и фамилия"
          name="name"
          value={name}
          onChange={onChangeNameHandler}
          status={eventErrors.name ? 'error' : 'default'}
          bottom={eventErrors.name ? 'Необходимо ввести имя и фамилию' : ''}
          className="PanelEventForm__nameInput"
        />
      </ModalCard>
    </ModalRoot>
  );
};

AddGuestManually.propTypes = {
  eventErrors: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  addGuest: PropTypes.func.isRequired,
};

export default AddGuestManually;
