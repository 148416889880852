import React, {Component} from 'react';
import {
  Panel,
  PanelHeader,
} from '@vkontakte/vkui';
import {push} from 'react-router-redux';
import BackButton from '../BackButton';
import {popHistory, pushHistory, swipeOn} from '../../store/globals/actions';
import PropTypes from 'prop-types';

export default class DefaultPanel extends Component {
  constructor(props) {
    super(props);

    // Настройки по-умолчанию
    this.title = '';
    this.theme = 'white';
    this.hasBack = false;
    this.hasSwipe = false;
    this.customLeft = undefined;
    this.backPage = undefined;
    this.hasHeader = true;

    this.state = {
      snackbar: null,
    };

    this.pConstructor();
  }

  openPage(page) {
    const {dispatch} = this.props;

    dispatch(push('/' + page));
  }

  componentDidMount() {
    const {dispatch, id} = this.props;

    if (this.hasSwipe) {
      dispatch(pushHistory(id));
      dispatch(swipeOn());
    }
    this.pComponentDidMount();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    this.pComponentWillMount();
  }

  customBack() {
    const {dispatch} = this.props;

    if (this.hasSwipe) {
      dispatch(popHistory());
    }
  };

  renderPanel() {}

  settings() {}

  pConstructor() {}

  pComponentDidMount() {}

  pComponentWillMount() {}

  render() {
    const {id, dispatch} = this.props;
    this.settings();

    return (
      <Panel id={id} theme={this.theme}>
        {this.hasHeader &&
          <PanelHeader
            theme="light"
            left={[
              this.hasBack &&
              <BackButton
                key={'back_btn'}
                customBack={() => this.customBack}
                backPage={this.backPage}
                dispatch={dispatch}
              />,
              (!this.hasBack && this.customLeft !== undefined) && this.customLeft,
            ]}
          >
            {this.title}
          </PanelHeader>
        }

        {this.renderPanel()}
      </Panel>
    );
  }
}

DefaultPanel.propTypes = {
  id: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
};
