import {routerReducer} from 'react-router-redux';
import {combineReducers} from 'redux';
import {vkReducer} from './vk/reducers';
import {globals} from './globals/reducers';
import {logger} from './logger/reducers';
import {events} from './events/reducers';
import {friends} from './friends/reducers';
import {queryParams} from './query/reducers';

export const rootReducer = combineReducers({
  router: routerReducer,
  vk: vkReducer,
  globals: globals,
  logger: logger,
  events: events,
  friends: friends,
  query: queryParams,
});
