import React, {Component} from 'react';
import {
  Cell,
  FormLayout,
  Input,
  Textarea,
  Button,
  Div,
} from '@vkontakte/vkui';
import {connect} from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {registerLocale} from 'react-datepicker';
import {
  CREATE_PATH,
  EDIT_PATH,
} from '../../../constants/path';
import ru from 'date-fns/locale/ru';
import PropTypes from 'prop-types';
import './PanelEventForm.css';
import {setVisitors} from '../../../store/events/actions';
import {setModal} from '../../../store/globals/actions';
import AddGuestManually from '../../AddGuestManually';

registerLocale('ru', ru);

class PanelEventForm extends Component {
  state = {
    showName: false,
    name: '',
    title: this.props.eventData.title || '',
  };

  addGuest = () => {
    const {eventErrors, dispatch} = this.props;

    dispatch(setModal(
      <AddGuestManually
        dispatch={dispatch}
        addGuest={this.onClickAddVisitor}
        eventErrors={eventErrors}
      />
    ));
  };


  renderDatePickerForSafari() {
    const {
      eventErrors,
      eventData,
      changeDateHandler,
    } = this.props;

    return (
      <div className={'FormLayout__row pad0 ' + (eventErrors.visit_date ? ' error' : '')}>
        <div className="FormLayout__row-top">Дата посещения</div>
        <DatePicker
          selected={eventData.visit_date}
          onChange={changeDateHandler}
          dateFormat="dd.MM.yyyy"
          locale="ru"
          popperPlacement="bottom-start"
          placeholderText="Выберите дату"
        />
        {eventErrors.visit_date &&
        <div className="FormLayout__row--s-error">
          <div className="FormLayout__row-bottom">
            {eventData.visit_date === ''
              ? 'Необходимо ввести дату посещения'
              : 'Дата введена неверно'
            }
          </div>
        </div>
        }
      </div>
    );
  }

  renderDatePickerCustom() {
    const {
      eventErrors,
      eventData,
      changeHandler,
    } = this.props;

    return (
      <Input
        type="date"
        top="Дата посещения"
        name="date"
        value={eventData.visit_date}
        onChange={changeHandler}
        status={eventErrors.visit_date ? 'error' : 'default'}
        placeholder="Выберите дату"
        bottom={
          eventErrors.visit_date
            ? (eventData.visit_date === '' ? 'Необходимо ввести дату посещения' : 'Дата введена неверно')
            : ''
        }
      />
    );
  }

  renderDatePicker() {
    const {
      isSafari,
    } = this.props;

    return (
      isSafari
        ? this.renderDatePickerForSafari()
        : this.renderDatePickerCustom()
    );
  }

  renderDateShortCase() {
    return (
      <div className="PanelEventForm__hasVKIdBtn">
        <Button
          level="tertiary"
          onClick={() => this.setState({hasVKId: false})}
        >
          сегодня
        </Button>
        <Button
          level="tertiary"
          onClick={() => this.setState({hasVKId: false})}
        >
          завтра
        </Button>
      </div>
    );
  }

  renderListOfGuests() {
    const {
      pathname,
      eventData: {
        visitors,
      },
    } = this.props;

    if (visitors.length === 0) {
      return;
    }

    const isCreate = pathname === CREATE_PATH;

    return (
      <div className="PanelEventForm__listOfGuests">
        {
          visitors.map((visitor) => {
            return (
              <Cell
                key={`${visitor.name}${visitor.vk_id}`}
                removable={visitors.length > 1 || isCreate}
                onRemove={() => this.onRemoveGuest(visitor.name)}
                className="PanelEventForm__guest"
              >
                {visitor.name}
              </Cell>
            );
          })
        }
      </div>
    );
  }

  onClickAddVisitor = (name) => {
    const {
      dispatch,
      eventData: {
        visitors,
      },
    } = this.props;
    let friendAlreadyAdded = false;

    if (name.length === 0) {
      return;
    }

    if (visitors.length !== 0) {
      friendAlreadyAdded = !!visitors.find((visitor) => {
        if (visitor.name === name) {
          return visitor.name;
        }
      });
    }

    // если друг уже добавлен - ничего не делаем
    if (friendAlreadyAdded) {
      return;
    }

    const list = [...visitors];

    list.push({name});

    dispatch(setVisitors(list));
  };

  onRemoveGuest = (name) => {
    const {
      dispatch,
      eventData: {
        visitors,
      },
    } = this.props;

    const newList = [...visitors].filter((visitor) => {
      return visitor.name !== name;
    });

    dispatch(setVisitors(newList));
  };

  render() {
    const {
      eventData,
      pathname,
      changeHandler,
      createEvent,
      editEvent,
      deleteEvent,
      openFriendsList,
    } = this.props;
    const isEdit = pathname === EDIT_PATH;
    const isCreate = pathname === CREATE_PATH;
    const saveBtnDisabled = !eventData.visit_date || eventData.visitors.length === 0;

    return (
      <FormLayout className="PanelEventForm__form">
        <Div className="PanelEventForm__guests">
          <div className="PanelEventForm__label PanelEventForm__label--guestsList">
            Список гостей
          </div>
          {this.renderListOfGuests()}
          <div className="PanelEventForm__addGuestButtons">
            <Button
              size="l"
              stretched
              level="outline"
              onClick={openFriendsList}
              className="PanelEventForm__addGuestFromVK"
            >
              Из ВКонтакте
            </Button>
            <Button
              size="l"
              stretched
              level="outline"
              className="PanelEventForm__addGuestCommonBtn"
              onClick={this.addGuest}
            >
              Ввести вручную
            </Button>
          </div>
        </Div>
        {this.renderDatePicker()}
        <Textarea
          top="Цель посещения (необязательно)"
          placeholder=""
          name="purpose"
          value={eventData.purpose}
          onChange={changeHandler}
        />
        {eventData.visitors.length > 1 && (
          <Input
            type="text"
            top="Название приглашения (необязательно)"
            name="title"
            value={eventData.title}
            status={eventData.title.length > 25 ? 'error' : 'default'}
            bottom={eventData.title.length >= 26 ? 'Слишком длинное название приглашения' : ''}
            onChange={changeHandler}
          />
        )}
        {isEdit &&
        <React.Fragment>
          <Button
            size="xl"
            stretched
            onClick={editEvent}
            style={{marginTop: '20px'}}
          >
            Сохранить
          </Button>
          <Button
            size="xl"
            stretched
            onClick={deleteEvent}
            style={{marginTop: '10px'}}
            level="destructive"
          >
            Отменить приглашение
          </Button>
        </React.Fragment>
        }
        {isCreate &&
        <Button
          size="xl"
          stretched
          onClick={createEvent}
          disabled={saveBtnDisabled}
        >
          Создать приглашение
        </Button>
        }
      </FormLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    eventData: state.events.event,
    eventErrors: state.events.errors,
    activeId: state.events.activeId,
    pathname: state.router.location.pathname,
    accessToken: state.vk.accessToken,
    isDesktop: state.globals.isDesktop,
  };
};

PanelEventForm.propTypes = {
  eventData: PropTypes.object.isRequired,
  eventErrors: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  isSafari: PropTypes.bool.isRequired,
  changeDateHandler: PropTypes.func.isRequired,
  changeHandler: PropTypes.func.isRequired,
  editEvent: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  openFriendsList: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(PanelEventForm);
