import React from 'react';
import {capitalize} from './js/services/helpers';
import PanelMain from './js/components/panels/PanelMain';
import PanelEvents from './js/components/panels/PanelEvents/PanelEvents';
import PanelWrongUser from './js/components/panels/PanelWrongUser';
import PanelEventView from './js/components/panels/PanelEventView/PanelEventView';
import PanelEventCreateEdit from './js/components/panels/PanelEventCreateEdit';
import PanelFriends from './js/components/panels/PanelFriends';

export default class RoutesAndPanels {
  static sharedInstance =
    RoutesAndPanels.sharedInstance == null
      ? new RoutesAndPanels()
      : this.sharedInstance;

  constructor() {
    this.routes = {};
  }

  setRoutes() {
    this.addRoute('main', PanelMain);
    this.addRoute('create', PanelEventCreateEdit);
    this.addRoute('edit', PanelEventCreateEdit);
    this.addRoute('all-visits', PanelEvents);
    this.addRoute('view', PanelEventView);
    this.addRoute('wrong-user', PanelWrongUser);
    this.addRoute('friends', PanelFriends);
  }

  addRoute(url, panel, withNum = false, props = []) {
    this.routes[url] = {};
    this.routes[url]['url'] = url;
    this.routes[url]['panel'] = panel;
    this.routes[url]['id'] = 'panel' + capitalize(url);
    this.routes[url]['props'] = props;
    this.routes[url]['withNum'] = withNum;
  }

  getUrls() {
    const arr = Object.values(this.routes);

    return arr.map((route) => (
      route['withNum'] ? route['url'] + '_\\d+' : route['url']
    ));
  }

  getSelectedPanel(route) {
    let selected = 'panelMain';
    const arr = Object.values(this.routes);

    for (let i = 0; i < arr.length; i++) {
      if (route.indexOf('_') === -1) {
        if (arr[i]['url'] === route) {
          selected = arr[i]['id'];
          break;
        }
      } else {
        if (arr[i]['url'] === route.split('_')[0]) {
          selected = arr[i]['id'];
          const urlNum = route.split('_')[1];
          this.routes[arr[i]['url']]['props'] = {...this.routes[arr[i]['url']]['props'], urlNum};
          break;
        }
      }
    }

    return selected;
  }

  getSwipeStatus(url) {
    let swipeStatus = false;
    let route = url.substr(1);
    const arr = Object.values(this.routes);

    for (let i = 0; i < arr.length; i++) {
      if (route.indexOf('_') === -1) {
        if (arr[i]['url'] === route) {
          swipeStatus = arr[i]['panel'].hasSwipe;
          break;
        }
      } else {
        if (arr[i]['url'] === route.split('_')[0]) {
          swipeStatus = arr[i]['panel'].hasSwipe;
          break;
        }
      }
    }

    return swipeStatus;
  }

  renderPanel(route) {
    const Panel = route['panel'];
    return <Panel id={route['id']} key={route['id']} {...route['props']} />;
  }

  renderPanels() {
    let arr = Object.values(this.routes);
    return arr.map((route) => (
      this.renderPanel(route)
    ));
  }
}
