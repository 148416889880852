import 'core-js/es6/map';
import 'core-js/es6/set';
import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import {Route} from 'react-router';
import {applyMiddleware, createStore} from 'redux';
import {createHashHistory} from 'history';
import {rootReducer} from './js/store/reducers';
import {ConnectedRouter, routerMiddleware} from 'react-router-redux';
import thunk from 'redux-thunk';
import RoutesAndPanels from './_RoutesAndPanels';
import {setHistory, swipeOff, swipeOn} from './js/store/globals/actions';
import {composeWithDevTools} from 'redux-devtools-extension';

const history = createHashHistory({
  hashType: 'noslash',
});

const logger = (store) => (next) => (action) => {
  console.log('dispatching', action);
  return next(action);
};

export const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(thunk, routerMiddleware(history), logger),
));

history.listen((location, action) => {
  let currentPage = location.pathname;
  let swipeStatus = RoutesAndPanels.sharedInstance.getSwipeStatus(currentPage);

  if (location.pathname === '/main') store.dispatch(setHistory(['panelMain']));

  if (swipeStatus) store.dispatch(swipeOn());
  else store.dispatch(swipeOff());
});

RoutesAndPanels.sharedInstance.setRoutes();

const urls = RoutesAndPanels.sharedInstance.getUrls();
const pathRegex = '/:pageId(' + urls.join('|') + '|)?';

ReactDOM.render(
  <Provider store={ store }>
    <ConnectedRouter history={ history }>
      <div>
        <Route path={ pathRegex } component={ (props) => <App pageId={ props.match.params.pageId }/> }/>
      </div>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
