export const PENDING = {
  status: 'pending',
  title: 'Ожидается',
};
export const PAST = {
  status: 'past',
  title: 'Завершено',
};
export const CANCELLED = {
  status: 'cancelled',
  title: 'Отменено',
};
export const SKIPPED = {
  status: 'skipped',
  title: 'Пропущено',
};

