import React, {Component} from 'react';
import {connect} from 'react-redux';
import {closePopout} from '../store/globals/actions';
import {Alert} from '@vkontakte/vkui';
import PropTypes from 'prop-types';

class DeleteConfirm extends Component {
    closePopout(dispatch) {
      dispatch(closePopout());
    };

    render() {
      const {deleteAction, dispatch} = this.props;
        return (
            <Alert
                actionsLayout="vertical"
                actions={[{
                    title: 'Отменить приглашение',
                    style: 'destructive',
                    action: deleteAction,
                }, {
                    title: 'Назад',
                    autoclose: true,
                    style: 'cancel',
                }]}
                onClose={() => this.closePopout(dispatch)}
            >
                <h2>Подтвердите действие</h2>
                <p>Вы уверены, что хотите отменить приглашение?</p>
            </Alert>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

DeleteConfirm.propTypes = {
    deleteAction: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(DeleteConfirm);
