import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Div} from '@vkontakte/vkui';
import './CreateNewEvent.css';

class CreateNewEvent extends PureComponent {
  render() {
    const {openCreatePanel} = this.props;

    return (
      <div className="CreateNewEvent">
        <Div className="CreateNewEvent__header">
           <div className="CreateNewEvent__title">
             Здесь Вы можете пригласить друга в&nbsp;офис
           </div>
          <div className="CreateNewEvent__curveContainer">
            <svg
              className="CreateNewEvent__curveIcon"
              height="8" viewBox="0 0 375 8"
              width="100%" xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
            >
              {/* eslint-disable-next-line max-len */}
              <path d="m187.254946 0c63.801259 0 126.383789 2.02155187 187.74759 6.06465561v1.45834439l-375.002536-.597v-.88911408c61.1816107-4.02459061 123.599926-6.03688592 187.254946-6.03688592z" fill="var(--background_content)" fillRule="evenodd"/>
            </svg>
          </div>
        </Div>
        <Div className="CreateNewEvent__content">
          <div className="CreateNewEvent__instruction">
            <ul className="CreateNewEvent__step-list">
              <li className="CreateNewEvent__step">
                <span className="CreateNewEvent__step-number">1</span>
                Создайте приглашение
              </li>
              <li className="CreateNewEvent__step">
                <span className="CreateNewEvent__step-number">2</span>
                Отсканируйте QR-код в терминале на&nbsp;первом этаже
              </li>
              <li className="CreateNewEvent__step">
                <span className="CreateNewEvent__step-number">3</span>
                Распечатайте пропуск
              </li>
            </ul>
          </div>
          <Button
            size="xl"
            level="primary"
            onClick={openCreatePanel}
          >
            Создать посещение
          </Button>
        </Div>
      </div>
    );
  };
}

CreateNewEvent.propTypes = {
  openCreatePanel: PropTypes.func.isRequired,
};

export default CreateNewEvent;

