import React from 'react';
import {Search, Avatar, Cell, List} from '@vkontakte/vkui';
import DefaultPanel from './DefaultPanel';
import {connect} from 'react-redux';
import LoaderSpinner from '../LoaderSpinner';
import {setFriends, setFriendsLoaded, setFriendsLoading} from '../../store/friends/actions';
import {getAllMyFriends} from '../../services/vk';
import {setVisitors} from '../../store/events/actions';
import {goBack} from 'react-router-redux';

class PanelFriends extends DefaultPanel {
  state = {
    search: '',
  };

  settings() {
    this.title = 'Друзья';
    this.hasBack = true;
    this.theme = 'white';
  }

  pComponentWillMount = async () => {
    const {dispatch, accessToken} = this.props;

    dispatch(setFriendsLoading());
    dispatch(setFriends([]));

    let [firstHalf, lastHalf] = await Promise.all(
    [
      getAllMyFriends(accessToken, 0),
      getAllMyFriends(accessToken, 5000),
    ]);

    let friends = [];
    firstHalf.items.map((friend) => (
      friends.push({
        id: friend.id,
        name: `${friend.first_name} ${friend.last_name}`,
        photo_50: friend.photo_50,
      })
    ));
    lastHalf.items.map((friend) => (
      friends.push({
        id: friend.id,
        name: `${friend.first_name} ${friend.last_name}`,
        photo_50: friend.photo_50,
      })
    ));

    dispatch(setFriends(friends));
    dispatch(setFriendsLoaded());
  };

  renderList = () => {
    return this.friends.map((friend) => (
      <Cell
        key={friend.id}
        onClick={() => this.setFriend(friend.id, friend.name)}
        before={
            <Avatar
              size={36}
              src={friend.photo_50}
            />
        }
      >
          {friend.name}
      </Cell>
    ));
  };

  get friends() {
    const {friends} = this.props;
    const {search} = this.state;
    const searchValue = search.toLowerCase();

    return (
      friends.filter(({name}) => (
        name.toLowerCase().indexOf(searchValue) > -1
      ))
    );
  }

  setFriend = (id, name) => {
    const {
      dispatch,
      eventData,
    } = this.props;
    let friendAlreadyAdded = false;

    if (eventData.visitors.length !== 0) {
      friendAlreadyAdded = !!eventData.visitors.find((visitor) => {
        if (+visitor.vk_id === id) {
          dispatch(goBack());
          return visitor.vk_id;
        }
      });
    }

    // если друг уже добавлен - ничего не делаем
    if (friendAlreadyAdded) {
      return;
    }

    const listOfVisitors = [...eventData.visitors];

    listOfVisitors.push({
      vk_id: id,
      name,
    });

    dispatch(setVisitors(listOfVisitors));
    dispatch(goBack());
  };

  changeSearch = (search) => {
    this.setState({search});
  };

  renderPanel() {
    const {isFriendsLoading} = this.props;
    const {search} = this.state;

    return (
      <React.Fragment>
        <Search theme='default' value={search} onChange={this.changeSearch}/>
        <List>
          {!isFriendsLoading && this.renderList()}
        </List>
        {isFriendsLoading && <LoaderSpinner/>}
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    accessToken: state.vk.accessToken,
    isFriendsLoading: state.friends.isFriendsLoading,
    friends: state.friends.friends,
    eventData: state.events.event,
  };
};

export default connect(mapStateToProps)(PanelFriends);
