import * as types from './actionTypes';
import * as React from 'react';
import {ScreenSpinner, PopoutWrapper} from '@vkontakte/vkui';

const initialState = {
  popout: '',
  modal: null,
  swipeStatus: true,
  history: ['panelMain'],
  isDesktop: false,
};

export const globals = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_POPOUT:
      return {
        ...state,
        popout: action.popout,
      };

    case types.SET_MODAL:
      return {
        ...state,
        modal: action.modal,
      };

    case types.SET_POPOUT_SPINNER:
      let popout = (
        <PopoutWrapper v="center" h="center">
          <ScreenSpinner/>
        </PopoutWrapper>
      );

      return {
        ...state,
        popout: popout,
      };

    case types.CLOSE_POPOUT:
      return {
        ...state,
        popout: '',
      };

    case types.CLOSE_MODAL:
      return {
        ...state,
        modal: null,
      };

    case types.PUSH_HISTORY:
      let historyPush = [...state.history];
      if (historyPush.indexOf(action.panelId) === -1) historyPush.push(action.panelId);
      return {
        ...state,
        history: historyPush,
      };

    case types.POP_HISTORY:
      let historyPop = [...state.history];
      historyPop.pop();
      return {
        ...state,
        history: historyPop,
      };

    case types.SET_HISTORY:
      return {
        ...state,
        history: action.history,
      };

    case types.CLEAR_HISTORY:
      return {
        ...state,
        history: [],
      };

    case types.SWIPE_ON:
      return {
        ...state,
        swipeStatus: true,
      };

    case types.SWIPE_OFF:
      return {
        ...state,
        swipeStatus: false,
      };

    case types.SET_IS_DESKTOP:
      return {
        ...state,
        isDesktop: true,
      };

    default:
      return state;
  }
};
