export const VK_ACCESS_TOKEN_GET_SUCCESS = 'VK_ACCESS_TOKEN_GET_SUCCESS';
export const VK_ACCESS_TOKEN_GET_FAIL = 'VK_ACCESS_TOKEN_GET_FAIL';
export const VK_INSETS_GET_SUCCESS = 'VK_INSETS_GET_SUCCESS';
export const VK_USER_GET_SUCCESS = 'VK_USER_GET_SUCCESS';
export const VK_USER_GET_FAIL = 'VK_USER_GET_FAIL';
export const VK_SET_API_START_LOADING = 'VK_SET_API_START_LOADING';
export const VK_SET_API_END_LOADING = 'VK_SET_API_END_LOADING';
export const VK_SET_JWT = 'VK_SET_JWT';
export const VK_SET_APP_ID = 'VK_SET_APP_ID';
export const VK_AUTH_USER_START = 'VK_AUTH_USER_START';
export const VK_AUTH_USER_FINISH = 'VK_AUTH_USER_FINISH';
